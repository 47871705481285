var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "engine-template-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("模板管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "page-body page-body-margin",
        },
        [
          _c(
            "a-card",
            { staticClass: "table-query-card", attrs: { title: "查询" } },
            [
              _c("jtl-table-query-component", {
                ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                on: { search: _vm.searchClick },
              }),
            ],
            1
          ),
          _c("a-card", { attrs: { title: "列表" } }, [
            _c(
              "div",
              {
                staticClass: "table-header-button",
                attrs: { slot: "extra" },
                slot: "extra",
              },
              [
                _c(
                  "jtl-button",
                  {
                    attrs: { type: "primary", "table-header-child": "" },
                    on: {
                      click: function ($event) {
                        return _vm.designClick()
                      },
                    },
                  },
                  [_vm._v("导入模板")]
                ),
                _c(
                  "jtl-button",
                  { attrs: { type: "primary", "table-header-child": "" } },
                  [_vm._v("新建模板")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "engine-template-table" }, [
              _c(
                "ul",
                { staticClass: "engine-template-table-content" },
                [
                  _vm._l(_vm.listData, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: item.id,
                        staticClass: "engine-template-table-item",
                      },
                      [
                        _c("div", { staticClass: "table-item-header" }, [
                          _vm._v(" " + _vm._s(item.templateName) + " "),
                        ]),
                        _c("div", { staticClass: "table-item-info" }, [
                          _c("span", [_vm._v(_vm._s(item.updatedTimeStr))]),
                          _c("span"),
                        ]),
                        _c("div", { staticClass: "table-item-content" }, [
                          _vm._v(" " + _vm._s(item.description) + " "),
                        ]),
                        _c("div", { staticClass: "table-item-typeandenable" }, [
                          _c("span", [_vm._v(_vm._s(item.mode))]),
                          _c(
                            "span",
                            [
                              _c("a-switch", {
                                on: {
                                  change: function ($event) {
                                    return _vm.onChange($event, 2, index, item)
                                  },
                                },
                                model: {
                                  value: item.enabled,
                                  callback: function ($$v) {
                                    _vm.$set(item, "enabled", $$v)
                                  },
                                  expression: "item.enabled",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "table-item-op" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.designClick(item.id)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewClick(item.id)
                                  },
                                },
                              },
                              [_vm._v("预览")]
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.manualTriggerClick(item)
                                  },
                                },
                              },
                              [_vm._v("手动触发")]
                            ),
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: "确认删除?",
                                  "ok-text": "确认",
                                  "cancel-text": "取消",
                                },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteClickItem(item)
                                  },
                                },
                              },
                              [
                                _c(
                                  "a-button",
                                  { attrs: { type: "danger", size: "small" } },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  !_vm.listData || _vm.listData.length === 0
                    ? _c("a-empty", {
                        staticStyle: {
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%) translateY(-50%)",
                          overflow: "hidden",
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "engine-template-pagination-box" },
                [
                  _c("a-pagination", {
                    staticClass: "engine-template-pagination-box-page",
                    attrs: {
                      current: _vm.page,
                      "page-size": _vm.pageSize,
                      "page-size-options": _vm.Pagination.pageSizeOptions,
                      total: _vm.total,
                      "show-total": function (total) {
                        return "共 " + total + " 项"
                      },
                      "show-size-changer": "",
                      "show-quick-jumper": "",
                    },
                    on: {
                      change: _vm.pageChange,
                      showSizeChange: _vm.pageChange,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("tempalte-sync-preview-dialog", { ref: "tempalteSyncPreviewDialog" }),
      _c("manual-trigger-detail-drawer", { ref: "manualTriggerDetailDrawer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }