var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: "手动触发",
        placement: _vm.placement,
        width: 800,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true,
      },
      on: { close: _vm.drawerClose },
    },
    [
      _c(
        "div",
        { staticClass: "device-type-detail-component table-component" },
        [
          _c(
            "div",
            { staticClass: "page-body page-body-bg-white" },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-col": { span: 4 },
                    "wrapper-col": { span: 14 },
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "报告名称", prop: "name" } },
                    [
                      _c("a-input", {
                        attrs: {
                          "allow-clear": "",
                          placeholder: "请输入报告名称",
                        },
                        model: {
                          value: _vm.form.templateName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "templateName", $$v)
                          },
                          expression: "form.templateName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "报告生成格式",
                        required: "",
                        prop: "reportFormat",
                      },
                    },
                    [
                      _c("a-select", {
                        attrs: {
                          options: _vm.docTypeList,
                          "allow-clear": "",
                          placeholder: "请选择报告生成格式",
                        },
                        model: {
                          value: _vm.form.reportFormat,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reportFormat", $$v)
                          },
                          expression: "form.reportFormat",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "报告发送对象", prop: "type" } },
                    [
                      _c(
                        "a-radio-group",
                        {
                          staticStyle: { "margin-top": "10px" },
                          on: { change: _vm.formTypeChange },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        [
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "ALL" },
                            },
                            [_vm._v("所有人 ")]
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "GROUP" },
                            },
                            [
                              _vm._v(" 指定部门 "),
                              _c("br"),
                              _vm.form.type === "GROUP"
                                ? _c("jtl-tree-select-component", {
                                    staticStyle: {
                                      width: "450px",
                                      "margin-top": "5px",
                                    },
                                    attrs: {
                                      multiple: true,
                                      "tree-data": _vm.orgTreeData,
                                      placeholder: "请选择指定部门",
                                    },
                                    model: {
                                      value: _vm.form.department,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "department", $$v)
                                      },
                                      expression: "form.department",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "GROUP_MANGER" },
                            },
                            [
                              _vm._v(" 指定部门负责人 "),
                              _c("br"),
                              _vm.form.type === "GROUP_MANGER"
                                ? _c("a-select", {
                                    staticStyle: {
                                      width: "450px",
                                      "margin-top": "5px",
                                    },
                                    attrs: {
                                      options: _vm.personList,
                                      mode: "multiple",
                                      placeholder: "请选择指定部门负责人",
                                    },
                                    model: {
                                      value: _vm.form.officer,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "officer", $$v)
                                      },
                                      expression: "form.officer",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "a-radio",
                            {
                              staticClass: "radioStyle",
                              attrs: { value: "SINGLE" },
                            },
                            [
                              _vm._v(" 指定人 "),
                              _c("br"),
                              _vm.form.type === "SINGLE"
                                ? _c("a-select", {
                                    staticStyle: {
                                      width: "450px",
                                      "margin-top": "5px",
                                    },
                                    attrs: {
                                      options: _vm.personList,
                                      mode: "multiple",
                                      placeholder: "请选择指定人",
                                      "allow-clear": "",
                                    },
                                    model: {
                                      value: _vm.form.user,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "user", $$v)
                                      },
                                      expression: "form.user",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: "消息发送渠道", prop: "messageChannel" },
                    },
                    [
                      _c(
                        "a-checkbox-group",
                        {
                          staticStyle: { margin: "10px 0" },
                          model: {
                            value: _vm.form.messageChannel,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "messageChannel", $$v)
                            },
                            expression: "form.messageChannel",
                          },
                        },
                        _vm._l(_vm.channelList, function (item) {
                          return _c(
                            "a-row",
                            { key: item.value },
                            [
                              _c(
                                "a-col",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { span: 24 },
                                },
                                [
                                  _c(
                                    "a-checkbox",
                                    { attrs: { value: item.value } },
                                    [_vm._v(_vm._s(item.label))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { "wrapper-col": { span: 14, offset: 6 } } },
                    [
                      _c("a-button", { on: { click: _vm.drawerClose } }, [
                        _vm._v(" 取消 "),
                      ]),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v(" 确定 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }