




















































































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { EngineTemplateModel, EngineTemplateQueryModel } from '@/entity-model/engine-template-entity';
import TempalteSyncPreviewDialog from './template-sync-preview-dialog.vue';
import EngineTemplateService from '@/service/engine-template.service';
import ManualTriggerDetailDrawer from './manual-trigger-detail.drawer.vue';

@Component({
    components: {
        'manual-trigger-detail-drawer': ManualTriggerDetailDrawer,
        'tempalte-sync-preview-dialog': TempalteSyncPreviewDialog
    }
})
export default class EdgeListComponent extends TableDialogFormComponent<EngineTemplateModel, EngineTemplateQueryModel> {
    EngineTemplateModel = EngineTemplateModel;
    private ids: Array<string> = [];
    created() {
        this.listLoading = true;
        this.initTable({
            service: new EngineTemplateService(),
            queryModel: new EngineTemplateQueryModel(),
            tableColumns: EngineTemplateModel.getTableColumns()
        });
        this.getList().then(() => {
            this.listLoading = false;
        });
    }
    // 删除
    deleteClickItem(item) {
        this.service.delete(item.id).then(() => {
            this.getList();
        });
    }
    // 手动触发
    manualTriggerClick(model: EngineTemplateModel) {
        (this.$refs.manualTriggerDetailDrawer as ManualTriggerDetailDrawer).drawerShow(model);
    }
    // 选择导出的模板
    onChange(e, f, index, item) {
        if (f === 1) {
            item.checked = e.target.checked;
            if (e.target.checked) {
                this.ids.push(item.id);
            } else {
                this.ids.splice(this.ids.findIndex(i => i === item.id), 1);
            }
        } else {
            // 切换是否启用
            this.startFullScreenLoading('正在处理...');
            EngineTemplateService.templateEngineEnabled({
                enabled: e,
                id: item.id
            }).then(res => {
                this.showMessageSuccess('处理成功');
            }).finally(() => {
                this.getList();
                this.stopFullScreenLoading();
            });
        }
    }
    // 触发page页
    pageChange(current, pageSize) {
        this.tableChange({
            current,
            pageSize
        }, null, null);
    }
    // 导入模板
    importTemplate(fileList) {
        if (fileList && fileList.length > 0) {
            this.startFullScreenLoading('正在导入模板...');
            return EngineTemplateService.importTemplateEngine(_.get(fileList, '[0].file')).then(res => {
                this.showMessageSuccess('导入模板成功');
            }).finally(() => {
                this.getList();
                this.stopFullScreenLoading();
            });
        }
    }
    // 导出模板
    exportTemplate() {
        if (!this.ids || this.ids.length === 0) {
            this.showMessageWarning('请先选择需要导出的模板！');
            return;
        }
        this.startFullScreenLoading('正在导出模板');
        EngineTemplateService.exportTemplateEngine(this.ids).then(res => {
            this.showMessageSuccess('导出模板成功');
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
    designClick(id) {
        const modeId = id ? id : 'add';
        this.$router.push(`/dashboard/template-manager-list/${modeId}`);
    }
    viewClick(templateId) {
        this.startFullScreenLoading('正在生成报告');
        EngineTemplateService.previewReportGenerate({
            templateId
        }).then(res => {
            (this.$refs.tempalteSyncPreviewDialog as TempalteSyncPreviewDialog).dialogOpen(res.url);
            this.showMessageSuccess('生成报成功');
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}

