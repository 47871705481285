





























































































































import { Component, Watch } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { TimeOptions, Dateformat, TemplateType, ChannelList } from '@common-src/filter/engine-template-type';
import { EngineTemplateModel, EngineTemplateQueryModel } from '@/entity-model/engine-template-entity';
import EngineTemplateService from '@/service/engine-template.service';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import OrgService from '@common-src/service/org';
import CommonService from '@common-src/service/common';

const engineTemplateService = new EngineTemplateService();

@Component({
    components: {}
})
export default class EdgeDetailDrawer extends DrawerComponent {
    model: EngineTemplateModel = new EngineTemplateModel();
    modelId: string = null;
    docTypeList: Array<any> = [];
    timeOptionsList: Array<any> = [{ value: 'null', label: '不限时间' }, ...TimeOptions];
    orgTreeData: Array<any> = [];
    personList: Array<any> = [];
    channelList: Array<any> = ChannelList;
    form: any = {
        templateId: '',
        templateName: '',
        reportFormat: undefined,
        type: '',
        department: [],
        officer: [],
        user: [],
        messageChannel: []
    };
    rules = {
        templateName: [
            { required: true, message: '请输入报告名称', trigger: 'blur' }
        ],
        reportFormat: [{ required: true, message: '请选择报告生成格式', trigger: 'change' }],
        // date1: [{ required: true, message: '请选择报告查询条件', trigger: 'change' }],
        messageChannel: [
            {
                type: 'array',
                required: true,
                message: '请选择消息发送渠道',
                trigger: 'change'
            }
        ],
        type: [{ required: true, message: '报告发送对象', trigger: 'change' }]
    };
    formTypeChange(e, newVal: string): void {
        this.form.department = [];
        this.form.officer = [];
        this.form.user = [];
    }
    drawerShow(model: EngineTemplateModel) {
        this.drawerOpen(model.id, model.templateName);
        this.modelId = model.id;
        this.form = {
            templateId: model.id,
            templateName: model.templateName,
            reportFormat: undefined,
            type: '',
            messageChannel: [],
            department: [],
            officer: [],
            user: []
        };
        this.form.reportFormat = model.mode;
        this.docTypeList = [
            { label: model.mode, value: model.mode },
            { label: 'PDF', value: 'PDF' }
        ];
        this.init();
    }

    init() {
        // this.startFullScreenLoading();
        OrgService.getSelectOrgTree({ noProject: false }).then(res => {
            this.orgTreeData = res;
        });
        CommonService.getPersonList().then(res => {
            this.personList = _.map(res, item => {
                return {
                    value: item.value,
                    label: item.name
                };
            });
        });
    }
    onSubmit() {
        (this.$refs.ruleForm as any).validate(valid => {
            if (valid) {
                this.startFullScreenLoading('正在处理...');
                let receiveId = [];
                switch (this.form.type) {
                    case 'GROUP':
                        receiveId = this.form.department;
                        break;
                    case 'GROUP_MANGER':
                        receiveId = this.form.officer;
                        break;
                    case 'SINGLE':
                        receiveId = this.form.user;
                        break;
                    default:
                        receiveId = [];
                        break;
                }
                const data = {
                    templateId: this.modelId,
                    reportName: this.form.templateName,
                    reportFormat: this.form.reportFormat,
                    receiveId,
                    receiveIdType: this.form.type,
                    messageChannel: this.form.messageChannel
                };
                EngineTemplateService.reportGenerate(data).then(res => {
                    this.drawerClose();
                }).then(res => {
                    this.showMessageSuccess('处理成功');
                }).finally(() => {
                    this.stopFullScreenLoading();
                });
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    }
    resetForm() {
        (this.$refs.ruleForm as any).resetFields();
    }
}
